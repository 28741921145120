import React from "react";
import _ from "lodash";
import { useStudents } from "../context/students";
import { useStudentsApi } from "./useStudentsApi";
import qs from "qs";

export const convertToCSV = (arr: any): any => {
  if (arr.length) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map((it) => {
        return Object.values(it)
          .map((cell) => {
            return String(cell).replaceAll(", ", " ").replaceAll(",", " ");
          })
          .toString();
      })
      .join("\n");
  } else {
    return [];
  }
};

export const deflate = function (source: any, pathArray?: any, result?: any) {
  pathArray = typeof pathArray === "undefined" ? [] : pathArray;
  result = typeof result === "undefined" ? {} : result;

  var key, value, newKey;

  for (var i in source) {
    if (source.hasOwnProperty(i)) {
      key = i;
      value = source[i];

      pathArray.push(key);

      if (typeof value === "object" && value !== null) {
        result = deflate(value, pathArray, result);
      } else {
        newKey = pathArray.join(".");
        result[newKey] = value;
      }

      pathArray.pop();
    }
  }

  return result;
};

export const useExportStudents = () => {
  const { filters, applyFiltersAndSearch } = useStudents();

  const studentsApi = useStudentsApi();

  const exportStudents = async (form: { fromDate: string; toDate: string }) => {
    const toDate = new Date(form.toDate);
    toDate.setDate(toDate.getDate() + 1);
    const fromDate = new Date(form.fromDate);

    //const queryFilters: any = buildQueryFilters();

    const query = qs.stringify({
      filters: {
        //...queryFilters,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      },
      pagination: {
        limit: -1,
      },
    });

    const {
      data: { data },
    } = await studentsApi.get(`/api/assessment/find-for-university?${query}`);

    const flattenAndOmitedData = data.map((s: any) => ({
      id: s.id,
      ..._.omit(s.attributes, [
        "nonEu",
        "updatedAt",
        "assessmentFormId",
        "assessmentFormEventId",
        "universityAcronym",
        "resultsUrl",
        "transactionEmailSent",
      ]),
      suggestedCources: s.attributes.suggestedCources?.map((c: any) => ({
        ..._.pick(c.attributes, ["link", "name", "specialization", "fitrate"]),
      })),
    }));

    const convertedFilters = {
      status: filters.status,
      language: filters.language,
      studyMode: filters.mode,
      degreeType: filters.degree,
    };

    const filteredData = applyFiltersAndSearch(
      flattenAndOmitedData,
      convertedFilters
    );

    return filteredData;
  };

  return { exportStudents };
};
